import { HttpClient, HttpParams } from '@angular/common/http';
import {EffectRef, Injectable, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, of, tap } from 'rxjs';
import { MarketOffer } from 'src/app/shared/models/exchange/market-offer.model';
import { Ticket, TicketData } from 'src/app/shared/models/exchange/ticket.model';
import { ModalService } from './modal.service';
import { ErrorModalParams } from 'src/app/shared/models/modal.model';

/**
 * TICKET SERVICE
 * Servicio para cargar los tickets de un evento y realizar operaciones de venta.
 * 
 * El servicio funciona de manera similar al AvailabilityService. En este caso no tenemos entidad, 
 * sin embargo queremos que, cuando obtengamos un id de evento seleccionado, se haga la llamada para obtener los datos
 * del ticket. Y si el usuario navega hacia otro, se vuelva a hacer la petición. Además incluyendo gestión de errores. 
 * 
 * El funcionamiento es el siguiente:
 * Tenemos dos señales privadas, _tickets y _eventId. La primera almacena los tickets del evento y la segunda el id del evento.
 * Cuando navegamos desde el componente MyEventsComponent a MyTicketsComponent, se setea el id del evento en la señal _eventId.
 * En caso de navegar de manera directa al componente, ya que este no tiene un hook onInit, se lanza el efecto del servicio recoverEventId, 
 * el cual verifica que el id del evento sea undefined y que el queryParam 'event' exista. Si es asi, se setea el id del evento y se dispara
 * el efecto _refreshTickets. Este efecto se encarga de resetear la señal _tickets y hacer la petición HTTP para obtener los tickets del evento.
 * 
 * Si la petición falla, se lanza un modal de error y se redirige al usuario a la ruta de retorno. Y si la petición es exitosa, se sobreescribe la señal
 * para mostrar los tickets actualizados en el componente. 
 * 
 * Además, el servicio incluye las llamadas para listar o deslistar el ticket en el mercado de venta. Estas llamadas son públicas y se llaman
 * desde el componente MyTicketsComponent. Finalmente, el servicio incluye un método para forzar la recarga de los tickets en caso de haber hecho cualquiera 
 * de las dos operaciones mencionadas anteriormente.
 */
@Injectable({
  providedIn: 'root'
})
export class TicketService {
  
  // SERVICES
  private http:      HttpClient      = inject(HttpClient);
  private modal:     ModalService    = inject(ModalService);
  private router:    Router          = inject(Router);
  private route:     ActivatedRoute  = inject(ActivatedRoute);

  // ENDPOINT AND STATE
  private endpoint:      string      = `/customer/`;
  public  isRefreshing!: boolean;
 
  // SIGNALS
  /**
   * Signal _TICKETS
   * Es la signal que contiene el ticket del Evento. Se setea mediante una petición http.
   * @type {WritableSignal<Ticket | undefined>}
   * 
   * Signal TICKETS
   * Es la señal pública de lectura mediante la cual se consumen los tickets en el componente. 
   * @type {Signal<Ticket | undefined>}
   * 
   * Singal _EVENTID
   * Señal que almacena el id del evento. Se setea mediante el método setEventId o se recupera
   * mediante el efecto _recoverEventId, el cual verificará que de entrada esta señal esté undefined 
   * y que el queryParam 'event' exista. Si es asi se setea y disparará el efecto _refreshTickets.
   * @type {WritableSignal<string | undefined>}
   * 
   * Signal EVENTID
   * Señal pública de lectura del id del evento. Para utilizar en las peticiones HTTP y en el componente.
   * @type {Signal<string | undefined>}
   */
  
  private _tickets:         WritableSignal<Ticket | undefined> = signal(undefined);
  public readonly tickets:  Signal<Ticket | undefined>         = computed(() => this._tickets());
  
  private _eventId:         WritableSignal<string | undefined> = signal(undefined);
  public readonly eventId:  Signal<string | undefined>         = computed(() => this._eventId());

  // EFFECTS
  /**
   * Efecto que se comprueba que el id del evento SEA undefined y que el queryParam 'event' exista.
   * Si es asi, se setea el id del evento y se dispara el efecto _refreshTickets por la detección 
   * de cambios en la señal _eventId.
   */
  
  private readonly _recoverEventId:  EffectRef = effect(() => {

    const param = this.route.snapshot.queryParams['event'];
    const path  = location.pathname.includes('my-tickets');

    if(this._eventId() === undefined && param && path){
      this.setEventId(this.route.snapshot.queryParams['event']);
    }

  },{allowSignalWrites: true})
  
  private readonly _refreshTickets:  EffectRef = effect(() => {
    if(this.eventId()) {
      this.resetTickets();
      this.getTickets();
    }
  },{allowSignalWrites: true});

  // GETTERS
  /**
   * Endpoint para listar los tickets
   * @returns {string} string
   */
  private get listTicketsEndpoint(): string {
    return `/ticket_exchange${this.endpoint}event/${this.eventId()!}/`;
  }

  /**
   * Endpoint para los Market Offers. Se utiliza en el create y delete de consignBack.
   * @returns {string} string 
   */
  private get marketOfferEndpoint(): string {
    return this.endpoint + 'market_offer/';
  }

  // METHODS
  /**
   * Método para setear el id del evento.
   * @param id {string}
   * @returns void
   */
  public setEventId(id: string): void {
    this._eventId.set(id);
  }

  /**
   * Método para forzar la recarga de los tickets.
   * @returns void
   */
  public refreshTickets(): void {
    this.getTickets();
  }
  
  /**
   * Retorna la petición para listar un ticket para vender.
   * @param eventId {number}
   * @param ticket  {TicketData}
   * @param parameter {string}
   * @returns {Observable<MarketOffer>} MarketOffer
   */
  public createConsignBack(eventId: number, ticket: TicketData, parameter?: string): Observable<MarketOffer> {
   
    const body: any = {
      event:          eventId,
      tickets:        [ticket.id],
      buyer_type_id:  ticket.buyer_type.id.toString(),
      patron:         ticket.attending_patron
    };

    const endpoint: string = this.marketOfferEndpoint;

    let queryParams = parameter ? {flow: parameter} : undefined;

    this.isRefreshing = true;

    return this.http.post<MarketOffer>(endpoint, body, {params: queryParams});
  }

  /** 
  * Retorna la petición para eliminar un ticket de la lista de venta.
  * @param marketOfferId {number}
  * @param buyerTypeId {number}
  * @param parameter {string}
  * @returns {Observable<string>} string 
  */
  public deleteConsignBack(marketOfferId: number, buyerTypeId: number, parameter?: string): Observable<string> {

    let queryParams = parameter ? 
      new HttpParams({fromObject:{ buyer_type_id: buyerTypeId, flow: parameter }}) : 
      new HttpParams({fromObject:{ buyer_type_id: buyerTypeId }});

    const endpoint: string = this.marketOfferEndpoint + `${marketOfferId}/cancel/`;

    this.isRefreshing = true;

    return this.http.delete<string>(endpoint, {params: queryParams});
  }

  /**
   * Método que llama a la petición HTTP para listar los tickets de un evento.
   * Si la petición falla, se lanza un modal de error y se redirige al usuario a la ruta de retorno.
   * Además gestiona el estado de isRefreshing y finalmente sobreescribe la señal _tickets con los tickets obtenidos.
   * @returns {void} void
   */
  private getTickets(): void {

    this.http.get<Ticket>(this.listTicketsEndpoint).pipe(
      catchError((error: any) => this.handleError(error)),
      tap(() => this.isRefreshing = false),
    ).subscribe((tickets: Ticket) => this._tickets.set(tickets));
    
  }

  /**
   * Método para resetear la señal _tickets.
   * @returns {void} void
   */
  private resetTickets(): void {
    this._tickets.set(undefined);
  }

  /**
   * Manejador de errores de la petición HTTP.
   * Crea un modal informando del error y redirige al usuario a la ruta de retorno.
   * @param error 
   * @returns 
   */
  private handleError(error: any): Observable<any> {
    
    // Si la petición falla mostramos un mensaje en consola.
    console.error('An error occurred:', error.error);

    // Parametros del modal de error
    const modalParams: ErrorModalParams = {
      content: error.error.message,
      onConfirm: () => this.router.navigate(['/exchange/my-events'])
    }
  
    // Mostramos un modal con el mensaje de error y redirigimos al usuario a la ruta de retorno.
    this.modal.createErrorModal(modalParams);
    
    return of(null);
  }

}
