<div class="flex flex-col h-full overflow-y-auto lg:overflow-hidden">
    <app-back-button></app-back-button>

    <!-- TITLE -->
    <section class="container mx-auto text-center my-5 lg:mt-0 lg:mb-20">
        <h2 class="text-darkgray/50 dark:text-lightgray/50 font-medium text-xl mb-1 lg:font-bold lg:text-lg">
            {{ templateVars.header}}
        </h2>
        <h4 class="text-darkgray dark:text-lightgray/60 font-bold text-3xl mb-4 lg:text-4xl">
            {{ templateVars.subtitle}}
        </h4>
    </section>

    <!-- MAIN CONTENT -->
    <section *ngIf="userTraitData else loader" class="bg-white flex flex-col flex-grow lg:overflow-y-auto lg:py-10 ">

        <!-- BANK ACCOUNT -->
        <app-bank-account 
            [userTraitData]="userTraitData" 
            (verificationStatus)="setVerificationStatus($event)"
        />

        <!-- MY EVENTS  -->
        <div class="container mx-auto my-10 sm:max-w-xl md:max-w-6xl">

            <!-- TEXT -->
            <div class="text-center mx-5 lg:w-2/4 lg:mx-auto mb-9">
                <h4 class="text-darkgray dark:text-lightgray/60 font-bold text-3xl mb-4 lg:text-4xl">
                    {{templateVars.header2}}
                </h4>
                <p class="text-darkgray/50 dark:text-lightgray/50 font-medium text-base mb-1 lg:font-bold lg:text-lg">
                    {{templateVars.subtitle2}}
                </p>
            </div>

            <!-- EVENTS -->
            <ng-container *ngIf="userInventory() && userInventory()!.length else noInventory">
                <app-inventory-item 
                    *ngFor="let item of userInventory()" 
                    (viewTicket)="goToTicket( $event )" 
                    [item]="item" 
                    [verified]="userVerified" 
                />
            </ng-container>

            <!-- NO EVENTS -->
            <ng-template #noInventory>
                <div class="text-center text-darkgray/50 dark:text-lightgray/50 font-medium text-base mb-1 lg:font-bold lg:text-lg">
                    {{templateVars.noInventory}}
            </div>
            </ng-template>
        </div>

    </section>

    <ng-template #loader>
        <div class="flex justify-center items-center bg-white h-full">
            <app-loader/>
        </div>
    </ng-template>
</div>