<div class="flex flex-col h-full overflow-y-auto lg:overflow-hidden">
    <app-back-button/>

    <!-- TITLE -->
    <section class="h-1/5 my-5">
        
        <div class="flex container mx-auto flex-col justify-center items-center">
            <ng-container *ngIf="ticket() else textLoader">
                <h2 class="text-darkgray/50 dark:text-lightgray/50 font-medium text-xl mb-1 lg:font-bold lg:text-lg">My Tickets</h2>
                <h3 class="text-darkgray dark:text-lightgray/60 font-bold text-2xl lg:text-4xl mb-5">{{ ticket()?.event?.name }}</h3>
            </ng-container>
            
            <!-- SKELETON -->
            <ng-template #textLoader>
                <div class="h-5 ms-2 mt-5 bg-gray-300 rounded-full dark:bg-gray-600 w-32 animate-pulse"></div>
                <div class="h-5 ms-2 mt-5 bg-gray-300 rounded-full dark:bg-gray-600 w-60 animate-pulse"></div>
            </ng-template>
        </div>

    </section>

    <!-- TICKETS  -->
    <section class="lg:h-4/5 bg-white lg:overflow-y-auto">

        <div class="container mx-auto max-w-7xl pt-10 mb-20 lg:py-20">
            <!-- LOADER -->
            <div class="container mx-auto flex justify-center">
                <app-loader/>
            </div>

            <!-- TICKETS -->
            <div class="flex flex-col gap-y-5">

                <ng-container *ngIf="ticket() && !isRefreshing">
                   
                    <!-- TITLE  -->
                    <h5 class="font-main text-main text-xl lg:text-2xl font-semibold lg:font-medium mb-5 ml-6">
                        My Tickets
                    </h5>

                    <!-- TICKET CARDS -->
                    <div *ngFor="let ticket of ticket()?.tickets" class="w-11/12 lg:w-full flex flex-col lg:flex-row bg-main/5 border rounded-lg py-5 lg:px-7 mx-auto items-center gap-y-5">
                    
                        <div class="w-10/12 flex flex-col md:flex-row items-start lg:items-center gap-y-1">
                            
                            <!-- Section -->
                            <div class="w-full md:w-4/12 flex flex-col">
                                <p class="font-semibold text-main">
                                    Section
                                </p>
                                <span class="text-darkgray font-semibold font-main">
                                    {{ticket.seat.section}}
                                </span>
                            </div>
                            <!-- Details -->
                            <div class="w-full md:w-8/12 flex justify-between">
                                <!-- Row -->
                                <div class="lg:w-2/12 flex flex-col">
                                    <p class="font-semibold text-main">Row</p>
                                    <span class="text-darkgray font-semibold font-main">{{ticket.seat.row}}</span>
                                </div>
                            
                                <!-- Seat -->
                                <div class="lg:w-2/12 flex flex-col">
                                    <p class="font-semibold text-main">Seat</p>
                                    <span class="text-darkgray font-semibold font-main">{{ticket.seat.seat_number}}</span>
                                </div>
        
                                <!-- Price Scale -->
                                <div class="w-4/12 flex flex-col">
                                    <p class="font-semibold text-main">Price Scale</p>
                                    <span class="text-darkgray font-semibold font-main">{{removeParentesis(ticket.price_scale.publicDescription)}}</span>
                                </div>
                            </div>
                        </div>
                        
                        <!-- BUTTON TO LIST OR RELEASE -->
                        <button 
                        (click)="!ticket.currentlyOnMarketOffer ? releaseTicket(ticket) : recoverTicket(ticket.market_offer.id, ticket.buyer_type.id)" 
                        class="w-4/12 lg:w-2/12 transition-colors duration-300 ease-in-out"
                        [ngClass]="ticket.currentlyOnMarketOffer ? 'button-outline' : 'button'"
                        >
                            {{ticket.currentlyOnMarketOffer ? 'Delist' : 'List'}}
                        </button>
                    </div>

                </ng-container>

            </div>
        </div>

    </section>

</div>