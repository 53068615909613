import { Component, Signal, inject } from '@angular/core';
import { ModalService } from 'src/app/core/services/modal.service';
import { TicketService } from 'src/app/core/services/ticket.service';
import { Ticket, TicketData } from 'src/app/shared/models/exchange/ticket.model';
import { ConfirmationModalParams } from 'src/app/shared/models/modal.model';

@Component({
  selector: 'app-exchange-tickets',
  templateUrl: './exchange-tickets.component.html',
  styleUrl: './exchange-tickets.component.css'
})
export class ExchangeTicketsComponent {
  
  private ticketService: TicketService = inject(TicketService);
  private modalService:  ModalService  = inject(ModalService);
  
  protected ticket:      Signal<Ticket | undefined> = this.ticketService.tickets;
  
  
  /**
   * Getter que obtiene el valor de la propiedad isRefreshing del servicio TicketService.
   */
  protected get isRefreshing(): boolean {
    return this.ticketService.isRefreshing;
  }

  /**
   * Método helper para eliminar los parentesis de un string.
   * @param text 
   * @returns {string} string 
   */
  public removeParentesis(text: string): string {
    return text.replace(/\(.*?\)/g, '');
  }

  /**
   * Método para listar un ticket (poner en venta).
   * Lanza un modal de confirmación, y si el usuario confirma, se llama al método createConsignBack del servicio.
   * @param ticket
   * @returns void
   */
  protected releaseTicket(ticket: TicketData): void {
    
    const eventId: number = parseInt(this.ticketService.eventId()!),
          modalParams: ConfirmationModalParams = {
            title:   "Continue?",
            content: `<p>Are you sure you want to release this ticket?</p>`,
            onConfirm: () => this.ticketService.createConsignBack(eventId,ticket).subscribe({
              next: () => this.ticketService.refreshTickets(),
              error: error => console.error(error)
            }),
          }
     
    this.modalService.createConfirmationModal(modalParams);
  }

  /**
   * 
   * @param marketOfferId 
   * @param buyerTypeId 
   */
  protected recoverTicket(marketOfferId: TicketData['market_offer']['id'], buyerTypeId: TicketData['buyer_type']['id']): void {
    
    const modalParams: ConfirmationModalParams = {
              title:   "Continue?",
              content: '<p>Are you sure you wish to delist your ticket?</p>',         
              onConfirm: () => this.ticketService.deleteConsignBack(marketOfferId,buyerTypeId).subscribe({
                next: () => this.ticketService.refreshTickets(),
                error: error => console.error(error)
              })
            
          }

    this.modalService.createConfirmationModal(modalParams);
    
  }
}
