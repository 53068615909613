import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory } from 'src/app/shared/models/inventory.model';

@Component({
  selector: 'app-inventory-item',
  templateUrl: './inventory-item.component.html',
  styleUrl: './inventory-item.component.css'
})
export class InventoryItemComponent {

  @Input({required: true})
  public item!:  Inventory;

  @Input({required: true})
  public verified!:   boolean;

  @Output()
  public viewTicket:  EventEmitter<Inventory['id']> = new EventEmitter<number>();

  public notVerified: string = `<p>You can only View / List Tickets once your account has been verified.<br> Non-UK Supporters please contact us.</p>`;
  
  /**
   * Emite el ID del ticket al componente padre.
   * @param id ID del ticket
   * @returns void
   */
  public viewTickets(id: Inventory['id']): void {
    this.viewTicket.emit(id); 
  }
}
