<div class="flex flex-col md:flex-row bg-secondary/10 border border-darkgray/20 rounded-lg w-full items-center py-3 md:py-5 md:px-8 overflow-hidden">
    <!-- DETAILS -->
    <div class="w-10/12 md:w-8/12 flex flex-col gap-y-3 md:gap-y-0 md:flex-row justify-between">
        <div>
            <span class="font-semibold text-main font-main">Event</span>
            <p class="font-semibold text-gray-500">{{item.name}}</p>
        </div>

        <div>
            <span class="font-semibold text-main font-main">Venue</span>
            <p class="font-semibold text-gray-500">{{ item.venue }}</p>
        </div>

        <div>
            <span class="font-semibold text-main font-main">Date & Time</span>
            <p class="font-semibold text-gray-500">{{item.date | date:'M/d/yy h:mm a'}}</p>
        </div>
    </div>    
    
    <!-- BUTTON -->
    <ng-container *ngIf="verified else needVerified">
        <button class="ml-auto mr-5 md:mr-0 button w-4/12 md:w-2/12" (click)="viewTickets(item.id)">
            View Ticket
        </button>
    </ng-container>

    <!-- TEXT -->
    <ng-template #needVerified>
        <div class="text-sm ml-auto w-3/12 text-gray-500" [innerHTML]="notVerified"></div>
    </ng-template>

</div>
