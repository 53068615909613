 <!-- BANK FORM -->
 <div class="container mx-auto bg-lightgray/10 border border-lightgray p-8 max-w-6xl rounded-xl">

    <!-- BANK ACCOUNT DATA -->
    <form #traitDataForm='ngForm' (submit)="saveChanges(traitDataForm.value)" class="form container mx-auto lg:w-3/5" action="submit" *ngIf="hasTrait else createBankAccount">
        
        <!-- BANK DETAILS -->
        <div class="flex flex-col lg:flex-row flex-wrap justify-around gap-y-4 lg:gap-y-2">

            <!-- ACCOUNT -->
            <div class="lg:w-1/2 px-1 ">
                <label class="block" for="accNumber">
                    {{formVars.account}}
                </label>
                <input required (keypress)="onlyNumbers( $event )" class="block w-full disabled:text-gray-500 enabled:text-darkgray enabled:font-semibold enabled:border-secondary lg:text-lg" [disabled]="!editMode" [(ngModel)]="formValues.account" #account="ngModel" type="text" name="account" id="account" placeholder="45206244" autocomplete="cc-number" [maxlength]="8" [minlength]="8">
                
                <!-- ERROR HANDLING -->
                <ng-container *ngIf="account.invalid && account.touched">
                   
                    <div class="font-main text-sm text-red-500 lg:ml-2">                   
                       <span *ngIf="account.errors?.['required']">
                           This field is required.
                       </span>
   
                       <span *ngIf="account.errors?.['maxlength'] || account.errors?.['minlength']">
                           Account number must be 8 digits.
                       </span>
                   </div> 

                </ng-container>

            </div>

            <!-- CODE -->
            <div class="lg:w-1/2">
                <label class="block " for="accCode">
                    {{formVars.code}}
                </label>
                <input required (keypress)="onlyNumbers( $event )" class="block w-full enabled:border-secondary disabled:text-gray-500 enabled:text-darkgray enabled:font-semibold" [disabled]="!editMode" [(ngModel)]="formValues.code" #code="ngModel" type="text" name="code" id="code" placeholder="601811" autocomplete="off" [minlength]="6" [maxLength]="6">
                
                <!-- ERROR HANDLING -->
                <ng-container *ngIf="code.invalid && code.touched">
                    
                    <div class="font-main text-sm text-red-500 lg:ml-2">
                        <span *ngIf="code.errors?.['required']">
                            This field is required.
                        </span>

                        <span *ngIf="code.errors?.['maxlength'] || code.errors?.['minlength']">
                           Code must be 6 digits.
                        </span>
                    </div>    

                </ng-container>
            </div>

            <!-- NAME -->
            <div class="flex flex-col w-full">
                <label for="accName" class="block">
                    {{formVars.name}}
                </label>
                <input (keypress)="onlyText( $event )" class="block w-full enabled:border-secondary disabled:text-gray-500 enabled:text-darkgray enabled:font-semibold" #name="ngModel" [disabled]="!editMode" [(ngModel)]="formValues.name" type="text" name="name" id="name" placeholder="Tickets.com Ltd" [maxlength]="50">

                <ng-container *ngIf="name.invalid && name.touched">
                    
                    <span *ngIf="name.errors?.['required']">
                        This field is required.
                    </span>

                    <span *ngIf="name.errors?.['maxlength']">
                        Name must be less than 50 characters.
                    </span>

                </ng-container>
            </div>

        </div>
      
        <!-- STATUS AND TEXT-->
        <div class="text-sm text-darkgray mt-5">
            
            <!-- STATUS (VERIFIED OR NOT) -->
            <p class="mb-3">
                Verification: &nbsp;
                <span *ngIf="isVerified else notVerified" class="inline-block p-2.5 rounded-full bg-[#78B938]/20 text-green-500 animate-pulse">
                    Verified
                </span>
                <ng-template #notVerified>
                    <span class="inline-block p-2.5 rounded-full bg-red-600/20 text-red-500 animate-pulse">
                        Not verified
                    </span>
                </ng-template>
            </p>
            
            <!-- TEXT -->
            <p class="lg:prose text-xs md:text-sm lg:text-base">
                {{formVars.text}}
            </p>

        </div>

        <!-- BUTTONS -->
        <div class="mt-5 flex gap-x-3">
            
            <!-- Edit -->
            <ng-container *ngIf="!editMode else saveOrCancel">
                <button type="button" (click)="toggleEditMode()" class="button w-3/12 lg:w-2/12">Edit</button>
            </ng-container>
            
            <!-- Save or Cancel -->
            <ng-template #saveOrCancel>
                <button type="submit" [disabled]="account.invalid || code.invalid || name.invalid" class="button w-3/12 lg:w-2/12">Save</button>
                <button type="button" (click)="cancelEdit()" class="button-outline w-3/12 lg:w-2/12">Cancel</button>
            </ng-template>
            
        </div>
    
    </form>

    <!-- CREATE BANK ACCOUNT -->
    <ng-template #createBankAccount>
        <div class="flex flex-col items-center">
            <div class="font-main prose font-medium text-gray-500 prose-a:text-secondary prose-a:font-semibold" [innerHTML]="formVars.noTrait"></div>
            <button type="button" class="button w-3/12 lg:w-2/12 mt-3" (click)="createNewBankAccount()">
                Add Bank Account
            </button>
        </div>
    </ng-template>

</div>