import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Trait, TraitData } from 'src/app/shared/models/exchange/trait.model';

@Injectable({
  providedIn: 'root'
})
export class TraitService {

  private http: HttpClient = inject(HttpClient);

  protected endpoint: string = '/customer/trait/';

  public getTraitData(): Observable<Trait> {
    return this.http.get<Trait>(this.endpoint);
  }

  public createTrait(trait: TraitData): Observable<Trait> {
    return this.http.post<Trait>(this.endpoint, trait);
  }

  public updateTrait(traitId: number , traitData: TraitData): Observable<Trait> {
    return this.http.put<Trait>(this.endpoint + `/${traitId}/`, traitData);
  }

}
