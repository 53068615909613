import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalService } from 'src/app/core/services/modal.service';
import { TraitService } from 'src/app/core/services/trait.service';
import { Trait, TraitData, TraitForm } from 'src/app/shared/models/exchange/trait.model';
import { ConfirmationModalParams } from 'src/app/shared/models/modal.model';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrl: './bank-account.component.css'
})
export class BankAccountComponent implements OnInit {

  @Input()
  public userTraitData!:     Trait | undefined;                                                 // Trait del usuario inyectado desde el componente padre

  @Output()
  public verificationStatus: EventEmitter<boolean> = new EventEmitter<boolean>();               // Emite el estado de verificación

  private modal:      ModalService = inject(ModalService);
  private trait:      TraitService = inject(TraitService);

  public traitData:   TraitData | any = {};                                                     // Objeto Trait Data interno del componente, puede estar vacío o contener datos del Trait del usuario
  public editMode:    boolean         = false;
  public isVerified:  boolean         = false;

  /**
   * Variables de texto para el formulario.
   */
  public formVars: {account: string, code: string, name: string, text: string, modal: string, noTrait: string} = {
    account: 'UK Bank Account number',
    code:    'UK Bank Sort Code',
    name:    'Account name',
    text:    'Veritication: verified Supporters with overseas bank accounts need to email tickets@chelseafc.com with a copy of their bank statement providing bank name, SWIFT BIC and IBAN details for us to verify. If Supporters with UK bank accounts details are showing as Not Verified, please can you send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to tickets@chelseafc.com',
    modal:   `<p>You are submitting your bank details which will be listed as Not Verified.  Please send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to <a href="mailto:tickets@chelseafc.com">tickets@chelseafc.com</a> so it can be verified</p>`,
    noTrait: `You have not submitted your nominated bank account details.<br><br> Please click below and add in details for a UK bank account. Once submitted you will need to provide the Chelsea FC Ticket office team with proof of your bank account before any payment can be made. To verify, please can you send a copy of either a bank statement or a voided cheque confirming the details you have put on your account, along with your season ticket number to <a href="mailto:tickets@chelseafc.com">tickets@chelseafc.com</a><br><br> For Non-UK supporters (or those without a UK bank account) please contact the ticket office team.`
  }

  /**
   * Valores del formulario.
   */
  protected formValues: TraitForm = {
    account: '',
    code:    '',
    name:    '',
  }

  /**
   * Getter que verifica si el usuario tiene Trait.
   */
  protected get hasTrait(): boolean {
    return Object.values(this.traitData).length ? true : false;
  }

  /**
   * Inicializa el componente.
   */
  public ngOnInit(): void {
   this.initComponent();
  }

  /**
   * Cambia el estado del modo de edición. 
   * De manera habitual, si está en modo de edición, lo desactiva y viceversa.
   */
  public toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  /**
   * Crea un nuevo Trait Data Boilerplate interno del componente y activa el modo de edición.
   */
  public createNewBankAccount(): void {
    
    if(this.hasTrait){
      return;
    }
    
    this.traitData = {
      integerData: 0,
      stringData: '',
      memoData: '',
      booleanData: false,
    };

    this.toggleEditMode();
  }

  /**
   * Cancela la edición del formulario.
   * Si el Trait es boilerplate (creado desde el método createNewBankAccount), resetea el Trait Data interno del componente.
   * Si no, aplica los valores iniciales del Trait Data al formulario.
   * Finalmente, cambia el estado del modo de edición.
   */
  public cancelEdit(): void {

    // Check if the Trait is boilerplate
    if(this.traitData.integerData === 0) {
      this.resetTrait();
    } else {
      this.applyTraitData();
    }

    this.toggleEditMode();
    return;    

  }
  
  /**
   * Método helper que verifica que la tecla presionada sea un número.
   * @param event {KeyboardEvent}
   * @returns {boolean}
   */
  protected onlyNumbers(event: KeyboardEvent): boolean {
    const key: string = event.key;
    
    if(key < '0' || key > '9') {
      return false;
    }

    return true;
  }

  /**
   * Método helper que verifica que la tecla presionada sea de texto o la tecla espacio.
   * @param event {KeyboardEvent}
   * @returns {boolean}
   */
  protected onlyText(event: KeyboardEvent): boolean {
    const key: string = event.key;
    
    if((key < 'a' || key > 'z') && (key < 'A' || key > 'Z') && key !== ' ') {
      return false;
    }

    return true;
  }

  /**
   * Método que guarda los cambios realizados en el formulario. 
   * Genera un objeto Trait Data con los valores del formulario, lanza un modal 
   * de confirmación y llama al método verifyAccount.
   * @param form {TraitForm}
   */
  protected saveChanges(form: TraitForm): void {
    const {account, code, name} = form;
    
    const traitData: TraitData = {
      integerData:  parseInt(account),
      stringData:   code,
      memoData:     name,
      booleanData:  false,
    };

    const modalParams: ConfirmationModalParams = { 
      title:   'Continue?',
      content:  this.formVars.modal,
      onConfirm: ()=> this.verifyAccount(traitData)
    }

    this.modal.createConfirmationModal(modalParams);
  }

  /**
   * Comprueba si el usuario tiene Trait en la propiedad UserTraitData. 
   * Si esta está vacia, se llama a CreateTrait, en caso contrario, se llama a UpdateTrait.
   * Una vez realizada la llamada, se actualiza la propiedad UserTraitData con el Trait devuelto por la API y devolvemos el estado de verificación.
   * @param data {TraitData}
   */
  private verifyAccount(data: TraitData): void {
    
    const userHasTrait: boolean            = Object.values(this.userTraitData! ?? {}).length > 0,
          apiCall:      Observable<Trait>  = !userHasTrait ? this.trait.createTrait(data) : this.trait.updateTrait(this.userTraitData?.id!, data); 

    apiCall.subscribe({
      error:  (error: any) => console.error(error),
      next:   (res: Trait) => {
        this.toggleEditMode();
        this.userTraitData = res;
        this.isVerified    = res.traitData.booleanData;
        this.verificationStatus.emit(this.isVerified);
      }
    });
  }

  /**
   * Resetea el Trait Data interno del componente.
   */
  private resetTrait(): void {
    this.traitData = {};
  }

  /**
   * Asigna los valores del Trait Data a las variables del formulario y el estado de verificación.
   */
  private applyTraitData(): void {
    
    this.formValues = {
      account: this.traitData.integerData.toString(),
      code:    this.traitData.stringData,
      name:    this.traitData.memoData
    }

    this.isVerified = this.userTraitData!.traitData.booleanData;    
  }

  /**
   * Inicializa el componente
   * Si el usuario tiene Trait, asigna los valores del Trait Data a las variables del formulario
   * y emite el estado de verificación.
   */
  private initComponent(): void {
     // Si el usuario tiene Trait, asigna los valores del Trait Data a las variables del formulario
    if(this.userTraitData && Object.values(this.userTraitData).length > 0) {
      
      // Llenamos el objeto Trait Data interno del componente con los datos del Trait del usuario
      this.traitData = this.userTraitData.traitData;

      // Asigna los valores del Trait Data y Verified a las variables del formulario
      this.applyTraitData();

    }

    // Emite el estado de verificación
    this.verificationStatus.emit(this.isVerified);
  }
  
}
